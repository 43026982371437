:root {
  --color-success: #ffffff;
  --color-error: #ff0000;
  --color-info: #02c3ff;
  --toaster-width: 250px;
  --toaster-padding: 20px;
  --user-list-height: 21px;
  --user-list-padding-vertical: 5px;
  --user-list-padding-left: 10px;
  --table-header-active-width: 35px;
  --border-thickness-default: 1px;
  --admin-form-button-margin: 10px;
  --button-padding-vertical: 5px;
  --button-font-size: 13px;
  --filter-height: 40px;
  --transition-time: 0.3s;
  --led-size: 24px;
  --user-list-margin-bottom: 30px;
}

@font-face {
  font-family: AvantGarde;
  src: url(./fonts/AvantGarde/AvantGardeBook/AvantGardeBook.ttf);
}

.App {
  font-family: AvantGarde;
  color: #333333;
  padding-bottom: 30px;
}

.display-none {
  display: none;
}

.top-menu {
  position: fixed;
  top: 0;
  background-color: #61a1ab;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: right;
  z-index: 1;
}

.login {
  color: white;
  height: 40px;
  width: fit-content;
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  margin: auto 0;
  vertical-align: middle;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.user-icon {
  transform: scale(0.8);
  margin: auto 0;
}

.login-text {
  margin: auto 0;
}

.login-as {
  font-size: 13px;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.login-as-onclick {
  color: #61a1ab;
  cursor: pointer;
}

.placeholder-text:empty:not(:focus):before {
  content: attr(placeholder);
  color: #a1a1a1;
  font-weight: light;
  pointer-events: none;
}

.one-px-height {
  height: 1px;
}

.user-choice-menu {
  position: fixed;
  right: 0;
  display: flex;
  justify-content: right;
  transition: top var(--transition-time) ease-in-out;
  top: 40px;
  z-index: 0;
  background-color: white;
}

.user-choice-menu-up {
  top: 0px;
  transition: top var(--transition-time) ease-in-out;
  pointer-events: none;
}

.user-choice {
  border-left: 1px solid #61a1ab;
  border-bottom: 1px solid #61a1ab;
  height: fit-content;
  width: fit-content;
  padding: 2px 20px;
  color: #61a1ab;
  cursor: pointer;
}

.logo {
  width: fit-content;
  margin: 0 auto;
  transform: scale(0.7);
}

.title {
  margin-left: 36px;
  font-size: 16px;
}

.header-block {
  font-size: 14px;
}

.header-first {
  color: #61a1ab;
  margin-left: 36px;
}

.header {
  width: fit-content;
  margin: 0 auto;
  margin-top: 100px;
}

.pairing-instruction {
  width: fit-content;
  margin: 0 auto;
  margin-top: 50px;
}

.login-form {
  width: fit-content;
  margin: 0 auto;
  margin-top: 50px;
}

.login-form-header {
  color: #61a1ab;
  width: fit-content;
  margin: 0 auto;
}

fieldset {
  border-color: #61a1ab;
}

.login-form-body {
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 14px;
}

.login-form-button {
  width: fit-content;
  margin: 0 auto;
  margin-top: 30px;
}

.login-form-input {
  margin-top: 5px;
  width: 250px;
}

.button {
  background-color: #61a1ab;
  cursor: pointer;
  padding: var(--button-padding-vertical) 15px;
  font-size: var(--button-font-size);
  font-weight: bold;
  border-radius: 25px;
  color: white;
  letter-spacing: 0.05em;
}

.create-admin {
  cursor: pointer;
  color: #61a1ab;
}

.create-admin:hover {
  font-weight: bold;
}

.title-no-form {
  color: #61a1ab;
  width: fit-content;
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 20px;
  display: flex;
}

.title-no-form-second-row {
  color: #61a1ab;
  width: fit-content;
  margin: 0 auto;
  display: flex;
}

.training-data-title-outer {
  padding-top: 10px;
  padding-bottom: 0px;
}

.training-data-title {
  width: fit-content;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
  display: flex;
}

.settings {
  width: 254px;
  margin: 0 auto;
  font-size: 14px;
}

.justify-content-end {
  display: flex;
  justify-content: flex-end;
}

.settings-title {
  margin-top: 10px;
  font-weight: bolder;
}

.arrow {
  transform: scale(0.7);
  margin-top: -3.5px;
  padding: 0 10px;
  color: grey;
}

.selectable {
  cursor: pointer;
  color: #333333;
}

.admin-form-button {
  width: fit-content;
  margin: var(--admin-form-button-margin) auto;
}

.get-training-form-button {
  width: fit-content;
  margin: var(--admin-form-button-margin) auto;
  padding-bottom: 30px;
}

.searched-period-outer {
  padding-top: 5px;
}

.searched-period {
  margin: 5px auto;
  font-weight: bold;
  width: fit-content;
  height: fit-content;
  border-radius: 3px;
}

.admin-button {
  border: 1px solid #61a1ab;
  border-radius: 2px;
  width: 103px;
  height: 33px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  padding: 5px;
  display: flex;
}

.admin-button-selected {
  background-color: #61a1ab;
  color: white;
}

.margin-auto {
  margin: auto;
}

.between-admin-buttons {
  width: 24px;
}

.divider {
  width: 254px;
  border-bottom: 2px solid black;
  margin: 0 auto;
  padding-top: 30px;
  text-align: center;
}

.divider-less-padding {
  border-bottom: 2px solid black;
  padding-top: 10px;
}

.divider-handle {
  width: 254px;
  margin: 0 auto;
  border-bottom: 2px solid #c9c9c9;
  margin-top: 30px;
  margin-bottom: 20px;
}

.divider-mui-padding-top {
  width: 254px;
  margin: 0 auto;
  padding-top: 30px;
}

.divider-mui {
  width: 254px;
  margin: 0 auto;
}

.admin-subheader-outer {
  font-size: 14px;
  margin: 0 auto;
  margin-bottom: 30px;
  width: 254px;
}

.admin-subheader {
  padding: 0 5px;
  width: fit-content;
  border: 1px solid black;
  border-top: none;
  cursor: pointer;
}

.list-button {
  border: 1px solid #61a1ab;
  border-radius: 2px;
  width: fit-content;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  padding: 0 5px;
  margin-bottom: 20px;
}

.all-users-list-outest {
  font-size: 14px;
  width: fit-content;
  overflow: hidden;
  margin: 0 auto;
  transition: max-height var(--transition-time) ease-in-out;
}

.all-users-list-outer {
  background-color: #e2e2e2;
  border-radius: 3px;
  margin-bottom: var(--user-list-margin-bottom);
}

.padding-bottom-5px {
  padding-bottom: 5px;
}

.no-users {
  font-size: 14px;
  width: fit-content;
  margin: 0 auto;
}

.padding-top-30px {
  padding-top: 30px;
}

.max-height-0 {
  max-height: 0px;
}

.max-height-all-users {
  max-height: calc(
    var(--nr-of-users) *
      (var(--user-list-height) + var(--user-list-padding-vertical)) +
      var(--filter-height) + var(--user-list-margin-bottom)
  );
}

.all-admins-list-outer {
  font-size: 14px;
  width: fit-content;
  overflow: hidden;
  margin: 0 auto;
  transition: max-height var(--transition-time) ease-in-out;
  background-color: #e2e2e2;
  border-radius: 3px;
}

.max-height-all-admins {
  max-height: calc(
    var(--nr-of-users) *
      (var(--user-list-height) + var(--user-list-padding-vertical)) +
      var(--user-list-margin-bottom)
  );
}

.add-users-list-outer {
  overflow: hidden;
  margin: 0 auto;
  transition: max-height var(--transition-time) ease-in-out;
  margin-top: 20px;
}

.max-height-0-immediate {
  max-height: 0px;
  transition-duration: 0s;
}

.max-height-add-users-full {
  max-height: calc(
    var(--nr-of-users-to-add) *
      (
        var(--user-list-height) + (2 * var(--user-list-padding-vertical)) +
          var(--border-thickness-default)
      ) + var(--admin-form-button-margin) + (2 * var(--button-padding-vertical)) +
      var(--button-font-size) + var(--user-list-height) +
      (2 * var(--user-list-padding-vertical)) + 15px
      /*extra var(--user-list-height) +
      (2 * var(--user-list-padding-vertical)) at the end to avoid transition (scrolling) when adding new line in the table*/
  );
}

.font-size-12 {
  font-size: 12px;
}

.table-header-active {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: var(--table-header-active-width);
}

.table-header-user {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: calc(
    var(--user-list-padding-left) + 244px - var(--table-header-active-width)
  );
}

.table-header-time {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 40px;
}

.table-header-result {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 77px;
}

.table-header-result2 {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 56px;
}

.table-header-duration {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 45px;
}

.table-header-date {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 88px;
}

.table-header-day-nr {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: 80px;
}

.table-body-active {
  width: var(--table-header-active-width);
  margin-top: 5px;
}

.table-body-active-sessions {
  width: var(--led-size);
  margin-top: 5px;
}

.table-body-active-training-data {
  width: var(--table-header-active-width);
  margin-left: 8px;
  margin-top: 5px;
}

.table-body-user-id {
  margin-left: 27px;
}

.table-body-date {
  margin-left: 12px;
  width: 90px;
}

.table-body-time {
  width: 56px;
}

.table-body-day-nr {
  text-align: right;
  width: 40px;
}

.table-body-duration {
  text-align: right;
  margin-left: 10px;
  width: 40px;
}

.table-body-max-force {
  text-align: right;
  margin-left: 15px;
  width: 30px;
}

.table-body-average-force {
  text-align: right;
  margin-left: 14px;
  width: 30px;
}

.table-header-admin {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  width: calc(244px - var(--user-list-padding-left));
}

.all-users-list {
  width: 244px;
  height: var(--user-list-height);
  padding-left: var(--user-list-padding-left);
  padding-top: var(--user-list-padding-vertical);
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.add-users-list {
  margin: 0 auto;
  width: 244px;
  height: var(--user-list-height);
  border: var(--border-thickness-default) solid rgb(81, 81, 81);
  font-size: 14px;
  padding: var(--user-list-padding-vertical);
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
}

.no-border-top {
  border-top: none;
}

.no-border-bottom {
  border-bottom: none;
}

#toaster-lid {
  position: fixed;
  bottom: 0;
  padding: var(--toaster-padding);
  width: var(--toaster-width);
  left: calc(50% - var(--toaster-width) / 2 - var(--toaster-padding));
  background-color: #505050;
  text-align: center;
  pointer-events: none;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.07em;
  border-radius: 4px 4px 0px 0px;
}

.toaster-lid-text-visible {
  transition: opacity 0.4s;
  opacity: 1;
}

.toaster-lid-text-hidden {
  transition: opacity 0.4s;
  opacity: 0;
}

.lamp {
  width: 6px;
  height: 6px;
  border: 1px black solid;
  border-radius: 50%;
}

.background-green {
  background-color: #00c100;
}

.background-red {
  background-color: red;
}

.confirm-box {
  position: fixed;
  top: 300px;
  left: calc(50% - 113px);
  width: 244px;
  background-color: white;
  border: 2px solid black;
  border-radius: 3px;
  padding: 10px;
  z-index: 2;
}

.confirm-box-top {
  display: flex;
}

.confirm-box-text {
  margin-left: 10px;
}

.confirm-box-buttons {
  display: flex;
  justify-content: right;
  margin-top: 20px;
}

.confirm-box-button {
  margin: 10px;
}

.blur-background {
  background-color: rgba(50, 154, 240, 0.5);
  backdrop-filter: blur(3px);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.blur-background-intro {
  transition: opacity 0.8s ease-in-out;
  opacity: 1;
  visibility: unset;
}

.blur-background-outro {
  transition: opacity 0.8s ease-in-out, visibility 0.8s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.expand-collapse {
  margin-top: -4px;
  margin-left: -4px;
  height: 10px;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.rounded-bottom-corners {
  border-radius: 0 0 5px 5px;
}

.filter-outer {
  width: 254px;
  margin: 0 auto;
  justify-content: right;
  display: flex;
}

.filter {
  margin-right: 5px;
  margin-top: -20px;
  transform: scale(0.7);
  text-align: right;
  cursor: pointer;
  width: fit-content;
}

.all-users-filter-outer {
  width: 254px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: var(--filter-height);
}

.all-users-filter-separator {
  font-size: 13px;
  width: calc(254px / 3);
}

.radio-button {
  width: fit-content;
  transform: scale(0.7);
}

.all-users-filter {
  display: flex;
  width: fit-content;
  cursor: pointer;
}

.vertical-middle {
  margin: auto 0;
}

.led {
  transform: scale(0.33);
  margin: 0 auto;
  margin-top: -7px;
  width: var(--led-size);
  height: var(--led-size);
}

.led-2 {
  transform: scale(0.33);
  margin: 0 auto;
  margin-top: -9px;
  width: var(--led-size);
  height: var(--led-size);
}

.led-3 {
  transform: scale(0.33);
  margin-left: 12px;
  margin-top: -4px;
  width: var(--led-size);
  height: var(--led-size);
}

.led-red {
  background-color: #f00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px,
    rgba(255, 0, 0, 0.5) 0 2px 12px;
}

.led-orange {
  background-color: hsl(36, 100%, 50%);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
    hsl(36, 100%, 50%) 0 2px 12px;
}

.led-green {
  background-color: #a0ef00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
    #89ff00 0 2px 12px;
}

.led-grey {
  background-color: #8e8e8e;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px,
    #8e8e8e 0 2px 12px;
}
.spinner {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.user-stepper-outer {
  width: fit-content;
  margin: 0 auto;
  margin-top: 50px;
}

.user-stepper-mobile {
  width: 254px;
  font-size: 14px;
}

.spinner-transfer-data {
  margin-top: 50px;
  padding: 12px;
  border: 1px solid #cecece;
  border-radius: 6px;
}

.spinner-transfer-text {
  margin-top: 20px;
}

.connect-abort-buttons {
  padding-top: 10px;
  display: flex;
  justify-content: space-around;
}

.retry-abort-buttons {
  width: 254px;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
}

.font-size-16 {
  font-size: 16px;
}

.grid-template-rows-initial {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s;
}

.grid-template-rows-initial > div {
  overflow: hidden;
}

.detailed-training {
  margin: 0 5px;
  width: 244px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid grey;
}

.training-day {
  width: 244px;
  background-color: #d1eaee;
}

.expand-grid-template-rows {
  grid-template-rows: 1fr;
}

#calendar {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  margin: 0 auto;
  width: fit-content;
}

.download {
  transform: scale(0.75);
  margin-top: -4px;
  cursor: pointer;
}

.warning-icon-failed-to-upload {
  transform: scale(2);
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
}

.date-time-display {
  margin-left: 55px;
  letter-spacing: 0.05em;
  font-weight: bold;
  margin-top: 10px;
}

.time-display {
  margin-top: 5px;
}

.date-time-icons {
  margin-bottom: -5px;
  padding-right: 6px;
  transform: scale(0.8);
}

.training-data-info {
  margin: 0 auto;
  width: 254px;
}

.training-data-info-button {
  cursor: pointer;
  margin-bottom: -8px;
  transform: scale(0.8);
}

.bold {
  font-weight: bold;
}
